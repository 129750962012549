.Home .lander div a:first-child {
  margin-right: 20px;
}

.TtsForm .charCount p{
  display: 'block';
}

#charCount{
  font-size: 12px;
}

.fileName{
  font-size: 14px;
  font-weight: 400;
  padding: 15px 10px;
  background: #ededed;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

textarea {
  width: 100%;
  height: 100px;
  background: #ededed;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  resize: none;
}

/* .Ttsform .formModule{
  padding-top: 50px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-left: 80px;
} */