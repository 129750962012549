.Home .lander {
  padding: 10px 0;
  text-align: center;
}

.Home .lander h1,
.Home h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.Home .lander p {
  color: #999;
}

.Home .notes p {
  color: #666;
}

.Home .lander div {
  padding-top: 10px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.filePickerLabel{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
  margin-left: 0;
  padding-left: 0;
  text-align: right;
  float: right;
}

.Home .logo {
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-image: url("/gemini-logo.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
}

