h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
}

.App {
  margin-top: 50px;
  margin-bottom: 50px;
}

.App .navbar {
  font-weight: bold;
  background-color: #000;
  width: 100%;
    left: 0;
    position: fixed;
    top: 0;
    min-height: 50px;
    margin-bottom: 100px;
    border: none;
    border-radius: 0px !important;
    z-index: 1;
}

.App .navbar-brand{
  color: #2ac5bf;
  text-transform: uppercase;
}

.form-group{
  margin-bottom: 5px;
  padding-top: 0px;
}

.btn-default{
  background-color: #2ac5bf;
  border: #2ac5bf;
  color: #25303b;
  font-weight: 600;
  margin-right: 10px;
  margin-top: 10px;
}

.btn-default:hover{
  background-color: #44b3b3;
  color: #FFF;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus{
  background-color: #3fa7a6;
  border: none;
}

.btn-default:focus-visible{
  background-color: #3fa7a6;
  border: none;
}

.btn-success{
  border: 1px solid #44b3b3;
  color: #3fa7a6;
  background-color:#FFF;
  margin-right: 10px;
  margin-top: 10px;
}

.btn-success:hover{
  background-color: #44b3b3 !important;  
  border: 1px solid #44b3b3 !important;
}

.btn-link{
  border: none;
  color: #44b3b3;
}

.btn-info:link{
  background-color: #adb4b4;  
  border: 2px solid #44b3b3;
}
.btn-info:link{
  background-color: #adb4b4;  
  border: 2px solid #44b3b3;
}
.btn-info:link{
  background-color: #adb4b4;  
  border: 2px solid #44b3b3;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
  background-color: #FFF;
  padding: 20px;
  border-radius: 5px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.test-copy-button{
  content: 'Choose file';
  border: 1px solid #44b3b3;
  color: #3fa7a6;
  background-color: #FFF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0px;
  margin-left: 0 !important;
}

.custom-file-input{
  content: 'Choose file';
  border: 1px solid #44b3b3;
  color: #3fa7a6;
  background-color: #FFF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-right: 12px;
  padding-left: 10px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0px;
  margin-left: 0 !important;
}

.custom-file-input:hover::before {
  background-color: #44b3b3 !important;  
  border: 1px solid #44b3b3 !important;
  color: #FFF;
  text-shadow: none;
  margin-bottom: 0;
  margin-left: 0 !important;
}