.whiteBlock{
    overflow: hidden;
    background-color: #FFF;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}


